<template>
  <div class="flex flex-col">
    <div v-if="data && data.format" class="flex flex-col text-left">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Format</label>
          <select
            v-model="data.format"
            class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent"
          >
            <option
              v-for="(format, index) in formats"
              :key="`option-${index}`"
              :value="format"
            >
              {{ format }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
  name: 'new-pdf-settings',
  props: ['payload'],
  components: {},
  data() {
    return {
      data: {},
      formats: ['Querformat', 'Hochformat'],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = { ...this.payload }

      if (this.data.module.app_label === 'performance_evaluation') {
        this.formats = ['Hochformat', 'Hochformat komprimiert']
      } else {
        this.formats = ['Querformat', 'Hochformat', 'Hochformat komprimiert']
      }
    },
    save() {
      console.log(this.data.format);
      axios
        .patch(
          `/organisation/setting/pdf/${this.data.id}/`,
          { format: this.data.format },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(() => {
          this.$emit('close-modal', { show: false })
          this.$bus.$emit('reload-pdf-settings-data-table')
        })
    },
  },
  watch: {},
}
</script>
